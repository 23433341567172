$mainColor: linear-gradient(135deg, #eead92, #6018dc);

// Search Section
.SearchDiv {
  background: $mainColor;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  div:nth-child(1) {
    width: 35%;
    form {
      .search-bar {
        height: 55px;
        border-radius: 60px;
        border: none;
      }

      .btn-outline-success {
        color: white;
        border: 1px solid white;
        background: rgba(244, 223, 223);
        border-radius: 50%;
        margin-top: -5px;
        margin-left: -35px;
        img {
          width: 46px;
          height: 50px;
        }
      }
    }
    .SeriousNav {
      width: 100%;
      form {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: 35px;
        padding-bottom: 20px;
        button {
          background-color: inherit;
          color: #0d1f44;
          font-weight: bold;
          border: none;
          border-right: 2px solid #0d1f44;
          border-left: 2px solid #0d1f44;
          margin-right: 20px;
          transition: 0.5s ease-in-out;
          &:hover {
            color: rgb(212, 212, 212);
            text-decoration: underline;
          }
        }
      }
    }
  }
}

// Videos Section
.videosDiv2 {
  width: 100%;
  min-height: 100vh;
  //   background-color: antiquewhite;
  .videosContainer {
    padding-top: 30px;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    .Vid {
      width: 500px;
      height: 300px;
      margin-bottom: 35px;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      background: $mainColor;
      padding-bottom: 10px;

      div {
        width: 90%;
        height: 91%;
        margin: auto;
        margin-top: 14px;
        border-radius: 20px;
        iframe {
          border-radius: 20px;
        }
      }

      p {
        font-weight: bold;
        text-align: center;
        padding-bottom: 5px;
        padding-top: 5px;
        margin-top: -20px;
        color: white;
      }
    }

    .NoVideosFound {
      margin-top: 20px;
      font-weight: bold;
    }
  }
}

@import "../../helpers/mediaQueries";

@include Xs {
  .SearchDiv {
    div:nth-child(1) {
      width: 80%;
    }
  }
}

@include Sm {
  .SearchDiv {
    div:nth-child(1) {
      width: 70%;
    }
  }
}

@include Md {
  .SearchDiv {
    div:nth-child(1) {
      width: 50%;
    }
  }
}
