.Warnings {
  background-color: rgb(254, 235, 200);
  width: 100%;
  h4 {
    text-align: center;
    font-weight: bold;
    color: red;
  }
  ul {
    padding-top: 10px;
    padding-bottom: 10px;
    list-style: decimal;
    font-weight: 500;
  }
}
.video {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .seconds {
    font-weight: bold;
  }

  .watchDetails {
    color: black;
  }

  .videoContainer {
    background-color: firebrick;
    width: 80%;
    height: 75%;
    border-radius: 20px;
    overflow: hidden;
    .youtubeVideo {
      width: 100%;
      height: 100%;
    }
    .videoDescription {
      margin-top: 20px;
      font-weight: bold;
    }
  }
}

.noResult {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 500px;
}
