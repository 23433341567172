// .PreLoader {
//   .Loader {
//     background-color: rgb(14, 17, 31);
//     width: 100%;
//     height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     div:nth-child(1) {
//       background-color: rgb(14, 17, 31);
//       background-image: url(../../Static/images/Preloader/MeltLoader.gif);
//       background-repeat: no-repeat;
//       background-size: contain;
//       width: 50%;
//       height: 30%;
//     }
//   }
// }

// Preloader.scss
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Adjust as needed
  background-color: rgb(14, 17, 31);
  background-image: url(../../Static/images/Preloader/ForgaPay.gif);
  background-size: 25%;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999; // Make sure it's on top of everything
  transition: opacity 1s ease-in-out; // Add a smooth fade-in/fade-out effect
}

// Media Query
@import "../../helpers/mediaQueries";

@include Xs {
  .preloader {
    background-size: 60%;
  }
}

@include Sm {
  .preloader {
    background-size: 60%;
  }
}

@include Md {
  .preloader {
    background-size: 30%;
  }
}
