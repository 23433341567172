.SignUp {
  background-color: rgba(133, 34, 125);
  background: linear-gradient(-80deg, #eead92, rgba(133, 34, 125, 1));
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  .LoginDiv1 {
    padding-top: 10px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      h4 {
        width: 100%;
        color: white;
        font-size: 45px;
        word-wrap: break-word;
        font-weight: bold;
        text-align: center;
        margin: 0;
        padding: 0;
        font-weight: 400;
        text-shadow: 6px 6px 20px rgb(255, 255, 255);
      }
      .cashFlowICN {
        margin: 0;
        padding: 0px;
        width: 400px;
        height: 400px;
        margin-bottom: 25px;
        pointer-events: none;
      }
    }
  }
  .LoginDiv2 {
    width: 65%;
    .LoginDiv2Child1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 100%;
      height: 100%;
      border-radius: 20px 0px 0px 20px;
      .LoginDiv2Child1DV1 {
        width: 95%;
        h1 {
          text-align: center;
          margin-top: -30px;
          padding-bottom: 20px;
          color: rgba(34, 40, 72, 1);
        }
        form {
          width: 70%;
          margin: auto;
          .form-group {
            .ErrorHelp {
              color: red;
              // font-weight: bold;
            }
          }

          .validation-messages {
            p {
              font-weight: 500;
              font-size: 12px;
              text-align: left;
            }
            ul {
              font-size: 13px;
              color: red;
            }
          }
          input {
            border: 1px solid rgba(34, 40, 72, 1);
            margin-bottom: 5px;
          }
          label {
            color: rgba(69, 65, 65, 1);
            font-weight: bold;
            margin-top: 5px;
            margin-bottom: 5px;
          }
          a {
            display: block;
            text-align: center;
            text-decoration: none;
            transition: 1s;
            &:hover {
              text-decoration: underline;
              color: blue;
            }
          }
          .signupBtnContainer {
            .btn-primary {
              display: block;
              margin: auto;
              margin-top: 15px;
              width: 70%;
              border-radius: 20px;
              background-color: rgba(34, 40, 72, 1);
              border: none;
              color: white;
              font-weight: bold;
            }
          }
        }
        .ErrorMSG {
          background-color: #f5d7d7;
          width: 65%;
          margin: auto;
          padding: 10px;
          text-align: center;
          border: 1px solid #481323;

          p {
            color: #481323;
            font-weight: bold;
            margin: 0;
          }
        }
        .signup-LoginControl {
          width: 75%;
          margin: auto;
          padding-top: 10px;
          p {
            font-weight: bold;
            font-size: 12px;
            a {
              color: #561148;
              font-size: 12px;
              font-style: normal;
              font-weight: 283;
              line-height: normal;
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }
}

// Media Query

// Media Query
@import "../../helpers/mediaQueries";
@include Xs {
  .SignUp {
    display: block;
    height: 100%;
    .LoginDiv1 {
      width: 100%;
      p {
        font-size: 30px;
      }
      div {
        text-align: center;
        padding-bottom: 20px;
        h2 {
          font-size: 30px;
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    .LoginDiv2 {
      width: 100%;
      box-sizing: unset;
      .LoginDiv2Child1 {
        border-radius: 0%;
        height: 100%;
        .LoginDiv2Child1DV1 {
          padding: 20px;

          .signup-LoginControl {
            .BLControl {
              .BreakLine {
                width: 20%;
              }
            }
          }
          h1 {
            margin: 0;
          }
        }
      }
    }
  }
}

@include Sm {
  .SignUp {
    display: block;
    height: 100%;
    .LoginDiv1 {
      width: 100%;
      p {
        font-size: 30px;
      }
      div {
        text-align: center;
        padding-bottom: 20px;
        h2 {
          font-size: 30px;
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    .LoginDiv2 {
      width: 100%;
      box-sizing: unset;
      .LoginDiv2Child1 {
        border-radius: 0%;
        height: 100%;
      }
    }
  }
}

@include Md {
  .SignUp {
    display: block;
    height: 100%;
    .LoginDiv1 {
      width: 100%;
      p {
        font-size: 30px;
      }
      div {
        text-align: center;
        padding-bottom: 20px;
        h2 {
          font-size: 30px;
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    .LoginDiv2 {
      width: 100%;
      box-sizing: unset;
      .LoginDiv2Child1 {
        border-radius: 0%;
        height: 100%;
      }
    }
  }
}
