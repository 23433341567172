@mixin DeleteFormBtns {
  background: rgb(77, 48, 106);
  color: rgb(194, 75, 158);
  font-weight: bold;
  padding: 10px 23px;
  border-radius: 20px;
  border: none;
  transition: 1s;
}
// Blured Area To Confirm
.ConfirmDelete {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(159, 99, 136, 0.48);
  z-index: 99999;
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  .ConfirmDeleteDiv1 {
    padding: 20px;
    width: 50%;
    background-color: rgba(34, 40, 72, 0.9);
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
    .WarningIcn {
      color: yellow;
      font-size: 40px;
    }
    .ConfirmDeleteDiv1Child1 {
      // background-color: rgba(34, 40, 72 ,0.9);
      width: 100%;
      color: white;
      padding-top: 20px;
      h3 {
        margin: 0;
        padding: 5px;
      }
    }
    .ConfirmDeleteDiv1Child2 {
      form {
        display: flex;
        justify-content: space-between;
        margin: auto;
        padding-top: 25px;
        width: 40%;
        button {
          @include DeleteFormBtns();
        }
        button:nth-child(1) {
          &:hover {
            background-color: red;
            color: white;
          }
        }
      }
    }
  }
}
.Container {
  width: 50%;
  margin: auto;
  padding: 1px;

  .userDataEdit {
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 15px 15px 15px 15px;
    width: 100%;
    overflow: hidden;
    form {
      .validation-messages {
        width: 80%;
        margin: auto;
        margin-top: -10px;
        p {
          font-weight: 500;
          font-size: 12px;
          text-align: left;
        }
        ul {
          font-size: 13px;
          color: red;
        }
      }
      .row {
        .btn {
          display: block;
          width: 20%;
          margin: auto;
        }
      }
    }
    .Div_Title {
      background: linear-gradient(135deg, #fd6585, #0d25b9);
      height: 25%;
      color: white;
      padding-top: 15px;
      padding-bottom: 1px;
      p {
        text-align: center;
        font-weight: 700;
      }
    }
    .EditNameDiv2 {
      background: linear-gradient(135deg, #97abff, #123597);
      padding: 20px;
      form {
        .deleteAccDV {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            button {
              background-color: #b20000b2;
              border: none;
              font-weight: bold;
              transition: 0.5s;
              &:hover {
                background-color: red;
              }
            }
          }
        }
        .row {
          justify-content: center;
          margin-bottom: 20px;
          .btn {
            background-color: #56114882;
            // background-color: rgba(34, 40, 72, 1);
            border: none;
            font-size: 20px;
            font-weight: 600;
          }
        }

        .col-form-label {
          // width: 20%;
          color: white;
        }
        .col-sm-10 {
          // width: 60%;
          margin-bottom: 25px;
        }
        input {
          background: linear-gradient(135deg, #2e50c3, #0e34ab);
          border: none;
          border-radius: 10px;
          color: white;
        }
      }
    }
    .successMSG {
      color: greenyellow;
      text-align: center;
      margin-top: 25px;
    }
    .errorMSG {
      color: red;
      text-align: center;
      margin-top: 25px;
    }
  }
}

@import "../../helpers/mediaQueries";

@include Xs {
  .userDataEdit {
    .EditNameDiv2 {
      form {
        .row {
          .btn {
            display: block;
            margin: auto;
            width: 90%;
          }
        }
      }
    }
  }
  .ConfirmDelete {
    .ConfirmDeleteDiv1 {
      .ConfirmDeleteDiv1Child2 {
        form {
          width: 100%;
          flex-direction: column;
          .btn {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@include Sm {
  .Container {
    .userDataEdit {
      .EditNameDiv2 {
        form {
          .row {
            .btn {
              display: block;
              margin: auto;
              width: 90%;
            }
          }
        }
      }
    }
    .ConfirmDelete {
      .ConfirmDeleteDiv1 {
        .ConfirmDeleteDiv1Child2 {
          form {
            width: 100%;
            flex-direction: column;
            .btn {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
@include Md {
  .Container {
    .userDataEdit {
      .EditNameDiv2 {
        form {
          .row {
            .btn {
              display: block;
              margin: auto;
              width: 90%;
            }
          }
        }
      }
    }
    .ConfirmDelete {
      .ConfirmDeleteDiv1 {
        .ConfirmDeleteDiv1Child2 {
          form {
            width: 100%;
            flex-direction: column;
            .btn {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
