@mixin Sign_ForgetBtns {
  text-align: center;
  text-decoration: none;
  font-weight: 100;
  font-size: 12px;
  margin-left: 5px;
  color: rgba(86, 17, 72, 1);
  &:hover {
    text-decoration: underline;
  }
}
.Login {
  background: linear-gradient(-80deg, #eead92, #85227d);
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  .LoginDiv1 {
    width: 50%;
    padding: 20px;
    img {
      width: 120px;
      height: 68px;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90%;

      h1 {
        color: white;
        word-wrap: break-word;
        font-weight: bold;
        font-family: "paytone";
        margin-top: 10px;
        font-size: 50px;
      }
    }
  }
  .LoginDiv2 {
    width: 50%;
    .LoginDiv2Child1 {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 100%;
      height: 100%;
      border-radius: 20px 0px 0px 20px;
      .LoginDiv2Child1DV1 {
        width: 80%;
        h1 {
          text-align: center;
          padding-bottom: 10px;
          color: rgba(34, 40, 72, 1);
        }
        form {
          padding-top: 25px;
          width: 90%;
          margin: auto;
          input {
            border: 1px solid rgba(34, 40, 72, 1);
            margin-bottom: 20px;
            width: 80%;
            margin: auto;
          }
          label {
            color: rgba(34, 40, 72, 1);
            font-weight: bold;
            width: 80%;
            margin: auto;
            display: block;
            margin-bottom: 5px;
            &.passwordLabel {
              margin-top: 15px;
            }
          }

          span {
            margin-top: 5px;
            display: block;
            text-align: center;
            font-weight: bold;
            color: rgba(69, 65, 65, 1);
            font-size: 13px;
            .CreateAccBtn {
              @include Sign_ForgetBtns();
            }
            .ForgetPasswordBtn {
              @include Sign_ForgetBtns();
              font-weight: bold;
            }
          }

          .errorContainer {
            width: 80%;
            margin: auto;
            margin-top: 10px;
            text-align: center;
            .ErrorHelp {
              color: red;
              font-weight: bold;
            }
          }

          .btn-primary {
            display: block;
            margin: auto;
            width: 250px;
            margin-top: 15px;
            border-radius: 20px;
            background-color: rgba(34, 40, 72, 1);
            border: none;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

// .Login .LoginDiv2 .LoginDiv2Child1 .LoginDiv2Child1DV1 form .btn-primary

// Media Query
@import "../../helpers/mediaQueries";
@include Xs {
  .Login {
    display: block;
    height: 100%;
    .LoginDiv1 {
      width: 100%;
      p {
        font-size: 30px;
      }
      div {
        text-align: center;
        padding-bottom: 20px;
        h2 {
          font-size: 30px;
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    .LoginDiv2 {
      width: 100%;
      box-sizing: unset;
      .LoginDiv2Child1 {
        border-radius: 0%;
        height: 100%;
        .LoginDiv2Child1DV1 {
          padding: 20px;
          h1 {
            margin: 0;
          }
          form {
            .btn-primary {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@include Sm {
  .Login {
    display: block;
    height: 100%;
    .LoginDiv1 {
      width: 100%;
      p {
        font-size: 30px;
      }
      div {
        text-align: center;
        padding-bottom: 20px;
        h2 {
          font-size: 30px;
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    .LoginDiv2 {
      width: 100%;
      box-sizing: unset;
      .LoginDiv2Child1 {
        border-radius: 0%;
        height: 100%;
        .LoginDiv2Child1DV1 {
          padding: 20px;
          h1 {
            margin: 0;
          }
        }
      }
    }
  }
}

@include Md {
  .Login {
    display: block;
    height: 100%;
    .LoginDiv1 {
      width: 100%;
      p {
        font-size: 30px;
      }
      div {
        text-align: center;
        padding-bottom: 20px;
        h2 {
          font-size: 30px;
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    .LoginDiv2 {
      width: 100%;
      box-sizing: unset;
      .LoginDiv2Child1 {
        border-radius: 0%;
        height: 100%;
        .LoginDiv2Child1DV1 {
          padding: 20px;
          h1 {
            margin: 0;
          }
        }
      }
    }
  }
}
