.LoginDiv2 {
  .LoginDiv2Child1 {
    .LoginDiv2Child1DV1 {
      padding: 20px;
      h1 {
        padding: 0px;
        margin: 0px;
      }
      p {
        margin: 0;
        padding: 0;
        text-align: center;
        font-weight: bold;
      }
      form {
        .resetpasswordContainer {
          display: flex;
          .countriesDV {
            margin-right: 10px;
            margin-left: 10px;
            select {
              background-color: gray;
              border: none;
              height: 100%;
              border-radius: 0px 7px 7px 0px;
              font-weight: bold;
              option {
                background-color: gray;
                font-weight: bold;
              }
            }
          }
          .phoneNumberDV {
            width: 100%;
            input {
              padding-left: 12px;
              border-radius: 5px;
              height: 100%;
              width: 100%;
              font-weight: bold;
            }
          }
        }

        .errorMsg {
          color: red;
          font-weight: bold;
          margin: 0;
          margin-bottom: 0;
          padding-bottom: 0px;
          margin-top: 15px;
        }
        #Forget_PasswordBTN {
          width: 50%;
          border-radius: 20px;
          margin-top: 20px;
        }
      }
    }
  }
}
