$navbar-footer-bg-color: #0d1f44;

.Footer {
  background-color: $navbar-footer-bg-color;
  color: white;
  direction: ltr;
  .LogoContainer {
    text-align: center;
    padding-top: 20px;
    width: 12%;
    .LogoImg {
      padding: 0;
      margin: 0;
      width: 62.9px;
      height: 50px;
      pointer-events: none;
    }
    h2 {
      font-family: "pMarker";
      font-weight: 400;
      font-size: 36px;
      line-height: 52.45px;
      margin-left: 35px;
    }
  }
  .Footbody {
    background-color: $navbar-footer-bg-color;
    width: 100%;
    padding-bottom: 15px;
    .Line {
      background-color: rgba(253, 186, 159, 0.65);
      border: none;
      height: 2px;
      width: 80%;
      margin: auto;
      margin-bottom: 20px;
    }
    .Icns {
      display: flex;
      justify-content: right;
      width: 80%;
      margin: auto;
      align-items: center;
      p {
        text-align: center;
        width: 100%;
        color: rgb(208, 208, 208);
      }
      div {
        margin-bottom: 15px;
      }
    }
    .Icns div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 13%;
    }
    .FotIcn {
      font-size: 25px;
      color: white;
      transition: 0.5s;
      &:hover {
        color: gray;
      }
    }
  }
}

// Footer Direction
.footer-rtl {
  direction: rtl;
}

@import "../../helpers/mediaQueries";

@include Xs {
  .Footer {
    div:nth-child(2) {
      .Icns {
        justify-content: center;
      }
      .Icns div {
        justify-content: space-around;
        width: 100%;
      }
    }
    .LogoContainer {
      width: 25%;
      justify-content: center;
    }
    .Footbody {
      .Icns {
        flex-direction: column-reverse;
      }
    }
  }
  // Footer Direction
  .footer-rtl {
    direction: rtl;
  }
}

// Small Screens
@include Sm {
  .Footer {
    div:nth-child(2) {
      .Icns {
        justify-content: center;
      }
      .Icns div {
        justify-content: space-around;
        width: 100%;
      }
    }
    .LogoContainer {
      width: 25%;
    }
    .Footbody {
      .Icns {
        flex-direction: column-reverse;
      }
    }
  }
  // Footer Direction
  .footer-rtl {
    direction: rtl;
  }
}

@include Md {
  .Footer {
    div:nth-child(2) {
      .Icns {
        justify-content: center;
      }
      .Icns div {
        justify-content: space-around;
        width: 100%;
      }
    }
    .LogoContainer {
      width: 15%;
    }
    .Footbody {
      .Icns {
        flex-direction: column-reverse;
      }
    }
  }
  .footer-rtl {
    direction: rtl;
  }
}
