@mixin SignupBtn {
  border: none;
  margin-right: 20px;
  color: white;
  font-size: 32px;
  font-weight: 500;
  line-height: 35.36px;
  background-color: #651980;
  padding: 20px 39px;
  padding: 15px 20px;
  border-radius: 11px;
  font-weight: bold;
}

@mixin ContactUSBtn {
  border: none;
  margin-right: 20px;
  color: white;
  font-size: 32px;
  font-weight: 500;
  line-height: 35.36px;
  padding: 15px 20px;
  border-radius: 11px;
  background-color: transparent;
  border: 1px solid #651980;
  font-weight: bold;
  color: white;
}

.Home {
  .HomeSections {
    margin: auto;
    width: 95%;
  }

  .HomeSec1 {
    margin-top: 30px;
    background: linear-gradient(135deg, #eead92, #6018dc);
    border-radius: 40px 40px 359px 40px;
    margin-left: 20px;
    height: 80%;
    display: flex;
    margin-bottom: 30px;
    overflow: hidden;
    &.HomeSec1AR {
      border-radius: 40px 40px 40px 359px;
    }

    div:nth-child(1) {
      width: 80%;
      color: white;
      padding: 35px;
      p:nth-child(1),
      p:nth-child(2) {
        font-size: 40px;
        font-weight: bold;
        line-height: 50.72px;
      }
      p:nth-child(3) {
        margin: 30px 0px 30px 0px;
        width: 40%;
      }
      div {
        .SignupBtn {
          @include SignupBtn;
          a {
            color: white;
            text-decoration: none;
          }
        }
        .ContactBtn {
          @include ContactUSBtn;
          a {
            color: white;
            text-decoration: none;
          }
        }
      }
    }
    div:nth-child(2) {
      width: 60%;
      background-image: url("../../Static/images/character/Pattern.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: end;
      img {
        width: 361px;
        height: 361px;
        padding: 0px;
        pointer-events: none;
      }
    }
  }

  .HomeSec2 {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin-top: 10px;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: auto;

    h2 {
      color: #4b5d8f;
      font-size: 30px;
    }
    .HomeSec2Div1 {
      display: flex;
      align-items: center;
      width: 35%;
      p:nth-child(1) {
        font-size: 40px;
        font-weight: bold;
        line-height: 50.72px;
        color: #843ec9;
      }
      p:nth-child(2) {
        margin: 30px 0px 30px 0px;
        color: #b571b0;
      }
    }
    .HomeSec2Div2 {
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div:nth-child(1) {
        width: 250px;
        height: 360px;
        background-color: #ffffff;
        border-radius: 40px;
        box-shadow: 3px 3px 100px 2px #df878b4d;
        text-align: left;
        padding-left: 20px;
        padding-top: 20px;
      }
      div:nth-child(2) {
        width: 250px;
        height: 360px;
        background-color: #ffffff;
        border-radius: 40px;
        box-shadow: 3px 3px 100px 2px #df878b4d;
        text-align: left;
        padding-left: 20px;
        padding-top: 20px;
      }
      div:nth-child(3) {
        width: 250px;
        height: 360px;
        background-color: #ffffff;
        border-radius: 40px;
        box-shadow: 3px 3px 100px 2px #df878b4d;
        text-align: left;
        padding-left: 20px;
        padding-top: 20px;
      }
    }
  }

  .HomeSec3 {
    width: 100%;
    display: flex;
    align-items: center;
    .HomeSec3Div1 {
      width: 80%;
      img {
        transform: rotate(-12deg);
        padding: 0px;
        pointer-events: none;
      }
    }

    .HomeSec3Div2 {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      div {
        p:nth-child(1) {
          font-size: 40px;
          font-weight: bold;
          line-height: 50.72px;
          color: #843ec9;

          line-height: 70px;
        }
        p:nth-child(2) {
          margin: 5px 0px 5px 0px;
          color: #b571b0;
        }
        p:nth-child(3) {
          color: #b571b0;
        }
        button {
          @include SignupBtn;
          a {
            color: white;
            text-decoration: none;
          }
        }
        .ContactBtn2 {
          @include ContactUSBtn;
          a {
            color: #021b52;
          }
        }
      }
    }
  }

  #SaveIMg {
    transform: rotateX(-5deg);
    pointer-events: none;
  }

  #HomeSec3Div1ID {
    width: 60%;
  }

  .HomeSec3Container {
    width: 95%;
    margin: auto;
  }
}

// For Arabic Style
// .SignupAndContactAR
// {
//   text-align: center;
// }

// Media Query
@import "../../helpers/mediaQueries";

@include Sm {
  .Home {
    .HomeSec1 {
      display: block;
      div:nth-child(1) {
        div {
          .SignupBtn {
            margin-bottom: 15px;
          }
        }
      }
      img {
        display: none;
      }
    }
    .HomeSec2 {
      display: block;
      .HomeSec2Div1 {
        width: 100%;
      }
      .HomeSec2Div2 {
        flex-wrap: wrap;
        width: 100%;
        row-gap: 15px;
      }
    }
    .HomeSec3 {
      display: block;
      .HomeSec3Div1 {
        width: 100%;
        text-align: center;
        img {
          width: 50%;
          height: 50%;
        }
      }
      .HomeSec3Div2 {
        justify-content: center;
        div {
          text-align: center;
          margin-bottom: 30px;
        }
      }
      #HomeSec3Div1ID {
        width: 100%;
      }
    }
  }
}

@include Md {
  .Home {
    .HomeSec1 {
      display: block;
      img {
        display: none;
      }
    }
    .HomeSec2 {
      display: block;
      .HomeSec2Div1 {
        width: 100%;
      }
      .HomeSec2Div2 {
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-around;
      }
    }
    .HomeSec3 {
      display: block;
      .HomeSec3Div1 {
        width: 100%;
        text-align: center;
        img {
          width: 50%;
          height: 50%;
        }
      }
      .HomeSec3Div2 {
        justify-content: center;
        div {
          text-align: center;
          margin-bottom: 30px;
        }
      }
      #HomeSec3Div1ID {
        width: 100%;
      }
    }
  }
}
