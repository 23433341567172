.NotFound {
  height: 80vh;
  .NotFoundContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      color: #0d1f44;
      font-size: 120px;
      letter-spacing: 30px;
    }
    p {
      color: #0d1f44;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
