// _mediaQueries.scss

@mixin Xs
{
    @media (max-width: 575px)
    {
        @content;
    }
}

@mixin Sm
{
    @media (max-width: 768px) 
    {
        @content;
    }
}

@mixin Md
{
    @media (min-width:769px) and (max-width: 1170px)  
    {
        @content;
    }
}

@mixin Lg
{
    @media (min-width:1171px) {
        @content;
    }
}