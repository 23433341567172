.contact {
  // background-image: linear-gradient(135deg, #eead9295, #6018dc23), url('../../Static/images/contact-pages.png');
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  h1 {
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: center;
  }

  .break {
    color: black;
    height: 3px;
    border-radius: 5px;
    width: 80%;
    background-color: black;
    margin: auto;
    margin-top: 20px;
  }
  .Content {
    width: 80%;
    margin: 15px auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    .maps {
      width: 80%;
      height: 400px;
      margin: auto;
      overflow: hidden;
      iframe {
        border-radius: 5px;
        border: 2px solid black;
      }
    }
    .contactDetails {
      width: 80%;
      margin: auto;
      .formCont {
        padding: 0px 20px 0px 20px;
        color: white;
        font-weight: bold;
        // background-color: aqua;
        .form-group {
          margin-bottom: 20px;
          label {
            color: black;
          }
          .inp-msg-area{
            resize: none;
          }
          .contactSubmit {
            width: 100%;
            background-color: rgb(13, 31, 68);
          }
        }
      }
    }
  }
}

// Media Query
@import "../../helpers/mediaQueries";

@include Sm {
  .contact {
    .Content {
      flex-direction: column-reverse;
      width: 100%;
      .maps{
        width: 70%;
      }
    }
  }
}

@include Md {
  .contact {
    .Content {
      flex-direction: column-reverse;
      width: 100%;
      .maps{
        width: 70%;
      }
    }
  }
}
