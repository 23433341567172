.profileMain {
  display: flex;
  background-color: #ecf0ff;
  height: 100vh;
  .userEarns {
    background-color: #eead92;
    width: 20%;
    height: inherit;
    padding: 20px 10px;
    font-weight: bold;
    text-align: center;
    h3 {
      text-align: center;
      margin: 10px 10px;
    }
    .earningContainer {
      .showBalance {
        width: 85%;
        background-color: rgba(34, 40, 72, 1);
        padding: 5px;
        border: none;
        color: white;
        border-radius: 10px;
        margin-bottom: 20px;
      }
      .withdrowCash {
        width: 85%;
        background-color: rgba(34, 40, 72, 1);
        padding: 5px;
        border: none;
        color: white;
        border-radius: 10px;
      }
    }
  }
  .profileInfo {
    width: 70%;
    // background: linear-gradient(-80deg, #eead92, rgba(133, 34, 125, 1));
    background: linear-gradient(135deg, #eead92, #6018dc);
    margin: auto;
    padding: 5rem;
    // border-radius: 400px 76px 76px 0px;
    border-radius: 15px;
    position: relative;
    height: fit-content;

    .profileInfoDv1 {
      .UsName {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          margin-top: -10px;
        }
        h1 {
          margin-top: 20px;
          margin-left: 10px;
          font-size: 60px;
          color: white;
        }
      }
      .UsNum_Email {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        p {
          font-size: 36px;
          font-weight: bold;
          color: white;
        }
      }
    }
    .EditBtn {
      background-color: #101f529e;
      border: none;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      position: absolute;
      right: 0px;
      bottom: 19px;
      margin-right: 20px;
      color: white;
      font-size: 20px;
      font-weight: 500;
      transition: 0.5s;
      text-align: center;
      line-height: 70px;
      &:hover {
        background-color: white;
        color: #2f4fae;
      }
      .EditBtnWord {
        text-decoration: none;
        color: white;
        &:hover {
          color: #2f4fae;
        }
      }
    }
  }
  .withdrawNotify {
    background-color: red;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(109, 106, 108, 0.48);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    .notification {
      width: 50%;
      height: fit-content;
      border: 1px solid black;
      text-align: center;
      background-color: white;
      padding: 20px;
      border-radius: 10px;

      .withdrawP {
        font-weight: bold;
      }
      .withdrawNotifyClose {
        width: 60%;
        background-color: rgba(34, 40, 72, 1);
        padding: 5px;
        border: none;
        color: white;
        border-radius: 10px;
        margin-bottom: 20px;
      }
    }
  }
}

// Media Query
@import "../../helpers/mediaQueries";

@include Xs {
  .profileMain {
    display: block;
    height: fit-content;
    padding-bottom: 20px;
    .userEarns {
      width: 100%;
    }
    .profileInfo {
      width: 90%;
      overflow: hidden;
      margin-top: 20px;
      margin-bottom: 20px;
      .profileInfoDv1 {
        .UsName {
          display: block;
          text-align: center;
          h1 {
            font-size: 40px;
          }
        }
        .UsNum_Email {
          text-align: left;
          width: 100%;
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@include Sm {
  .profileMain {
    display: block;
    height: fit-content;
    padding-bottom: 20px;
    .userEarns {
      width: 100%;
    }
    .profileInfo {
      width: 90%;
      overflow: hidden;
      margin-top: 20px;
      margin-bottom: 20px;
      .profileInfoDv1 {
        .UsName {
          display: block;
          text-align: center;
          h1 {
            font-size: 40px;
          }
        }
        .UsNum_Email {
          text-align: left;
          width: 100%;
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@include Md {
  .profileMain {
    display: block;
    height: fit-content;
    padding-bottom: 20px;
    .userEarns {
      width: 100%;
    }
    .profileInfo {
      width: 90%;
      overflow: hidden;
      margin-top: 20px;
      margin-bottom: 20px;
      .profileInfoDv1 {
        .UsName {
          display: block;
          text-align: center;
          h1 {
            font-size: 40px;
          }
        }
        .UsNum_Email {
          text-align: left;
          width: 100%;
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
}
