@font-face {
  font-family: "forgaArabic";
  src: url(./Static/fonts/ArabicRegular.ttf);
}

.AR-Font {
  font-family: "forgaArabic";
}

.EN-Font {
  font-family: Arial, Helvetica, sans-serif;
}
