.Verify {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .verifyContainer {
    width: 50%;
    .card {
      .card-header {
        font-weight: bold;
        text-align: center;
        h5 {
          margin: 0;
        }
      }
      .card-body {
        padding: 15px 10px 15px 10px;
        width: 60%;
        margin: auto;

        .card-title {
          text-align: center;
          margin-bottom: 15px;
        }

        .card-data {
          .card-Data-Resend {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            margin: 20px 0px 10px 0px;
            p {
              margin: 0;
              font-weight: bold;
            }
            .resendControl {
              .verification-resend {
                color: rgb(41, 205, 150);
                background: none;
                border: none;
                font-weight: bold;
                &:hover {
                  cursor: pointer;
                }
              }
              .count-down {
                color: rgb(77, 77, 77);
                font-weight: 400;
                font-size: 13px;
                margin-left: 5px;
              } 
            }
          }
          .card-Data-otp {
            width: 100%;
            text-align: center;
            .inputsContainerCustomStyle {
              justify-content: space-between;
              // flex-wrap: wrap;
              padding-top: 10px;
              .inputCustomStyle {
                width: 50px;
                height: 45px;
                text-align: center;
                font-weight: bold;
                border-radius: 5px;
                background-color: gainsboro;
                border: none;
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                  display: none;
                }
              }
            }
            .ErrorMSG {
              padding: 10px;
              text-align: center;
              border: 1px solid #481323;
              margin: auto;
              background-color: #f5d7d7;
              p {
                color: #481323;
                font-weight: bold;
                margin: 0;
              }
            }
            .verifyOTP-BTN {
              width: 100%;
              margin: 15px 0px 15px 0px;
              background-color: rgb(98, 105, 143);
              border: none;
              width: 100%;
              &:hover {
                background-color: rgba(34, 40, 72, 1);
              }
            }
            .verifyProblem {
              font-size: 12px;
              text-decoration: none;
              display: block;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

// Media Query
@import "../../helpers/mediaQueries";

@include Xs {
  .Verify {
    height: 90vh;
    .verifyContainer {
      // width: 90%;
      .card {
        height: 100%;
        .card-body {
          .card-data {
            .card-Data-Resend {
              text-align: center;
              flex-direction: column;
              .verification-label {
                font-size: 13px;
              }
            }
            .card-Data-Resend {
              font-size: 12px;
            }
            .card-Data-otp {
              .inputsContainerCustomStyle {
                flex-wrap: wrap;
                gap: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@include Sm {
  .Verify {
    .verifyContainer {
      width: 90%;
      .card {
        height: 100%;
        .card-body {
          .card-data {
            .card-Data-Resend {
              text-align: center;
              flex-direction: column;
              .verification-label {
                font-size: 13px;
              }
            }
            .card-Data-Resend {
              font-size: 12px;
            }
            .card-Data-otp {
              .inputsContainerCustomStyle {
                flex-wrap: wrap;
                justify-content: center;
                gap: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@include Md {
  .Verify {
    .verifyContainer {
      width: 90%;
      .card {
        height: 100%;
        .card-body {
          .card-data {
            .card-Data-Resend {
              text-align: center;
              flex-direction: column;
              .verification-label {
                font-size: 13px;
              }
            }
            .card-Data-Resend {
              font-size: 12px;
            }
            .card-Data-otp {
              .inputsContainerCustomStyle {
                flex-wrap: wrap;
                justify-content: center;
                gap: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@include Lg {
}
