// Variables
$navbar-footer-bg-color: #0d1f44;

// NavBar
nav.navbar {
  background-color: $navbar-footer-bg-color;
  color: white;
  box-shadow: 0 5px 5px #c2c4c9;
  &.navbar-EnLang {
    font-family: cursive;
  }
}

.navBarBrandDV {
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .navbar-brand {
    padding: 0;
    margin: 0;
    width: 62.9px;
    height: 50px;
    pointer-events: none;
  }
  .forgaName {
    padding: 0;
    margin: 0;
  }
}

#navbarSupportedContent {
  margin-top: 15px;

  p {
    margin: 0;
  }

  ul {
    li {
      a {
        margin: 0;
        color: white;
        font-size: 33px;
      }
      .langBtn {
        background: none;
        border: none;
        color: white;
      }
    }
  }
}

#LinUL {
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  li:nth-child(5) {
    display: flex;
    align-items: center;

    p {
      display: block;
      margin-left: 5px;
    }

    .faGlobeIcn {
      margin: 5px 10px 0px 0px;
      cursor: pointer;
      width: 22px;
    }
  }
}

#navbarSupportedContent {
  font-size: 32px;
  line-height: 38.4px;
  font-display: auto;
}

.nav-item {
  color: white;

  .dropdown {
    .dropdown-toggle {
      border: none;
      background-color: $navbar-footer-bg-color;
      font-size: 25px;
      border: 1px solid rgba(237, 188, 188, 1);
    }
    .dropdown-menu {
      .profileBtn {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

.active {
  color: white;
  text-decoration: underline;
  text-decoration-color: #fe9696;
}

// Toggler
.navbar-toggler {
  background-color: white;
}

@import "../../helpers/mediaQueries";

// X Small Screens
@include Xs {
  .navBarBrandDV {
    .navbar-brand {
      width: 80px;
      height: 45px;
    }
  }
}

// Small Screens
@include Sm {
  .navBarBrandDV {
    .navbar-brand {
      // width: 80px;
      // height: 45px;
      width: 62.9px;
      height: 50px;
    }
  }
  .nav-item {
    .dropdown {
      .dropdown-toggle {
        width: fit-content;
      }
    }
  }
}

@include Md {
  .navBarBrandDV {
    .navbar-brand {
      width: 62.9px;
      height: 50px;
    }
  }
}
