@font-face {
  font-family: "Inkfree";
  src: url(../../Static/fonts/Inkfree.ttf);
}

@font-face {
  font-family: "payTone";
  src: url(../../Static/fonts/PaytoneOne-Regular.ttf);
}

.navbar-brand {
  color: white;
  &:hover {
    color: white;
  }
}

.Rest_Password {
  background: linear-gradient(-80deg, #eead92, rgba(133, 34, 125, 1));
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  .LoginDiv1 {
    width: 50%;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      h4 {
        width: 100%;
        color: white;
        font-size: 45px;
        word-wrap: break-word;
        font-weight: bold;
        text-align: center;
        margin: 0;
        padding: 0;
      }
      img {
        margin: 0;
        padding: 0px;
        width: 300px;
        height: 300px;
      }
    }
  }
  .LoginDiv2 {
    width: 50%;
    // background-color: beige;
    .LoginDiv2Child1 {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 100%;
      height: 100%;
      border-radius: 20px 0px 0px 20px;
      .LoginDiv2Child1DV1 {
        width: 80%;
        h1 {
          text-align: center;
          margin-top: -30px;
          padding-bottom: 20px;
          color: rgba(34, 40, 72, 1);
        }
        form {
          padding-top: 25px;
          padding-top: 20px;
          width: 70%;
          margin: auto;
          .form-group {
            .otp-Title_resend-Btn {
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;
              cursor: pointer;
              .resendBtn {
                border: none;
                background: none;
                font-size: 12px;
                color: rgb(41, 205, 150);
                font-weight: bold;
              }
              .count-down {
                cursor: default;
                color: rgb(83, 83, 83);
                font-size: 12px;
              }
            }
            .OtpContainerCustomStyle {
              gap: 15px;
              .inputCustomStyle {
                text-align: center;
                padding: 5px;
                background-color: gainsboro;
                border-radius: 5px;
                font-weight: bold;
              }
            }
          }
          .validation-messages {
            margin-top: -10px;
            p {
              font-weight: 500;
              font-size: 12px;
              text-align: left;
            }
            ul {
              font-size: 13px;
              color: red;
            }
          }
          input {
            margin-bottom: 20px;
            border: 1px solid rgba(34, 40, 72, 1);
            width: 100%;
          }
          label {
            color: rgba(34, 40, 72, 1);
            margin-bottom: 3px;
            font-weight: bold;
          }
          a {
            display: block;
            text-align: center;
            text-decoration: none;
            transition: 1s;
            &:hover {
              text-decoration: underline;
              color: blue;
            }
          }
          .btn-primary {
            display: block;
            margin: auto;
            width: 200px;
            margin-top: 15px;
            border-radius: 20px;
            background-color: rgba(34, 40, 72, 1);
            border: none;
            margin-top: 20px;
          }

          .Reset-errorMsg {
            color: red;
            font-weight: bold;
            margin: 0;
          }
        }
      }
    }
  }
}

// Media Query

// Media Query
@import "../../helpers/mediaQueries";
@include Xs {
  .Rest_Password {
    display: block;
    height: 100%;
    .LoginDiv1 {
      width: 100%;
      p {
        font-size: 30px;
      }
      div {
        text-align: center;
        padding-bottom: 20px;
        h2 {
          font-size: 30px;
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    .LoginDiv2 {
      width: 100%;
      box-sizing: unset;
      .LoginDiv2Child1 {
        border-radius: 0%;
        height: 100%;
        .LoginDiv2Child1DV1 {
          padding: 20px;
          h1 {
            margin: 0;
          }
        }
      }
    }
  }
}

@include Sm {
  .Rest_Password {
    display: block;
    height: 100%;
    .LoginDiv1 {
      width: 100%;
      p {
        font-size: 30px;
      }
      div {
        text-align: center;
        padding-bottom: 20px;
        h2 {
          font-size: 30px;
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    .LoginDiv2 {
      width: 100%;
      box-sizing: unset;
      .LoginDiv2Child1 {
        border-radius: 0%;
        height: 100%;
        .LoginDiv2Child1DV1 {
          padding: 20px;
          h1 {
            margin: 0;
          }
        }
      }
    }
  }
}

@include Md {
  .Rest_Password {
    display: block;
    height: 100%;
    .LoginDiv1 {
      width: 100%;
      p {
        font-size: 30px;
      }
      div {
        text-align: center;
        padding-bottom: 20px;
        h2 {
          font-size: 30px;
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    .LoginDiv2 {
      width: 100%;
      box-sizing: unset;
      .LoginDiv2Child1 {
        border-radius: 0%;
        height: 100%;
        .LoginDiv2Child1DV1 {
          padding: 20px;
          h1 {
            margin: 0;
          }
        }
      }
    }
  }
}
